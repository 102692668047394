<template>
  <div :class="wrapperClass">
    <div id="dropin-container"></div>
    <b-row v-if="enablePay()">
      <b-col sm="12" class="text-center">
        <b-button
          size=""
          variant="secondary"
          @click="closeModel"
          class="fw-btn mx-1"
          >Cancel</b-button
        >
        <b-button
          size=""
          variant="primary"
          id="submitTransaction"
          class="fw-btn mx-1"
          :disabled="payButtonDisabled"
          @click="dropinRequestPaymentMethod"
          >Pay {{ amount | currencyFormat }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dropin from 'braintree-web-drop-in'

export default {
  props: {
    authToken: {
      value: String
    },
    wrapperClass: {
      value: String
    },
    collectCardHolderName: {
      value: Boolean
    },
    enableDataCollector: {
      value: Boolean
    },
    enablePayPal: {
      value: Boolean
    },
    amount: {
      value: String
    }
  },
  created() {
    this.dropinCreate()
  },
  data() {
    return {
      errorMessage: '',
      dropinInstance: '',
      paymentPayload: '',
      dataCollectorPayload: '',
      payButtonDisabled: false
    }
  },
  methods: {
    closeModel: function() {
      this.enablePay = false
      this.$emit('closeModal')
    },
    enablePay() {
      return this.dropinInstance !== ''
    },
    dropinCreate() {
      const dropinOptions = {
        authorization: this.authToken,
        selector: '#dropin-container',
        translations: {
          chooseAnotherWayToPay: 'Choose another payment method'
        }
      }

      dropin.create(dropinOptions, (dropinError, dropinInstance) => {
        if (dropinError) {
          this.errorMessage = dropinError.message
          this.$emit('bt.error', this.errorMessage)
          return
        }
        this.dropinInstance = dropinInstance
      })
      this.payButtonDisabled = false
    },
    dropinRequestPaymentMethod() {
      this.payButtonDisabled = true
      this.dropinInstance.requestPaymentMethod((requestErr, payload) => {
        if (requestErr) {
          this.errorMessage = requestErr.message
          this.$emit('btError', this.errorMessage)
        } else {
          this.$emit('btSuccess', payload)
        }
      })
    }
  }
}
</script>
